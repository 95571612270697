import styled from "styled-components"

export const StyledUploader = styled.div`
  .files {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
  }
  .files input {
    /* outline: 2px dashed #92b0b3;
    outline-offset: -10px; */
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
    opacity: 0;
  }
  .files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #92b0b3;
  }
  .files {
    position: relative;
    &:after {
      pointer-events: none;
      position: absolute;
      top: 60px;
      left: 0;
      width: 50px;
      right: 0;
      height: 56px;
      content: "";
      background-image: url("https://image.flaticon.com/icons/png/128/109/109612.png");
      display: block;
      margin: 0 auto;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &:before {
      position: absolute;
      bottom: 10px;
      left: 0;
      pointer-events: none;
      width: 100%;
      right: 0;
      height: 57px;
      /* content has to be included in single quotes inside the query string! */
      content: ${props => `'${props.fieldTranslation}'`};
      display: block;
      margin: 0 auto;
      color: ${props => props.theme.colors.purple};
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
    }
  }
  .error-files {
    outline: ${props => `2px dashed ${props.theme.colors.red}`};
  }
  .error-message {
    display: block;
    color: ${props => props.theme.colors.red};
    font-size: 1.6rem;
    margin-top: -1.5rem;
    margin-left: 1rem;
  }
`
