export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = phone => {
  const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
  return re.test(String(phone))
}

export const validateName = name => {
  return name && name.length > 3
}

export const validateAddress = address => {
  return address && address.length > 8
}

export const validatePostal = postal => {
  return postal && postal.length === 4 && !isNaN(postal)
}

export const validateAccept = accept => {
  return accept === true
}

export const validateFields = (setErrorMsg, formData, errorMsgs) => {
  const isNameValid = validateName(formData.name)
  const isEmailValid = validateEmail(formData.email)
  const isPhoneValid = validatePhone(formData.phone)
  const isAddressValid = validateAddress(formData.address)
  const isAcceptValid = validateAccept(formData.accept)
  const isPostalValid = validatePostal(formData.postal)

  if (!isNameValid) {
    setErrorMsg(prevState => {
      return { ...prevState, name: errorMsgs.name }
    })
    document.querySelector('[name="name"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="name"]')?.classList.remove("is-invalid")
    document.querySelector('[name="name"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, name: null }
    })
  }
  if (!isEmailValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        email: errorMsgs.email,
      }
    })
    document.querySelector('[name="email"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="email"]')?.classList.remove("is-invalid")
    document.querySelector('[name="email"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, email: null }
    })
  }
  if (!isPhoneValid) {
    setErrorMsg(prevState => {
      return { ...prevState, phone: errorMsgs.phone }
    })
    document.querySelector('[name="phone"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="phone"]')?.classList.remove("is-invalid")
    document.querySelector('[name="phone"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, phone: null }
    })
  }

  if (!isPostalValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        postal: errorMsgs.postal,
      }
    })
    document.querySelector('[name="postal"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="postal"]')?.classList.remove("is-invalid")
    document.querySelector('[name="postal"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, postal: null }
    })
  }

  if (!isAddressValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        address: errorMsgs.address,
      }
    })
    document.querySelector('[name="address"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="address"]')?.classList.remove("is-invalid")
    document.querySelector('[name="address"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, address: null }
    })
  }
  if (!isAcceptValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        accept: `${errorMsgs.accept}`,
      }
    })
    document.querySelector('[name="accept"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="accept"]')?.classList.remove("is-invalid")
    document.querySelector('[name="accept"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, accept: null }
    })
  }
  return (
    isNameValid &&
    isEmailValid &&
    isPhoneValid &&
    isAddressValid &&
    isAcceptValid &&
    isPostalValid
  )
}

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Base64 Reader Error: ", error)
      reject(error)
    }
  })
}
