import styled from "styled-components"
export const StyledForm = styled.form`
  label {
    margin-bottom: 0;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.4rem !important;
  }
  .myForm-header {
    text-transform: uppercase;
    font-size: 2rem;
  }
`
