import React from "react"
import styled from "styled-components"

const StyledUploadBar = styled.div`
  transition: all 0.5s linear;
  .bar-holder {
    width: 100%;
    height: 50px;
    border: ${props => `1px solid ${props.theme.colors.purple}`};
    border-radius: 8px;
  }

  .bar {
    width: ${props =>
      props.max > 0 ? `${(props.progress / props.max) * 100}%` : 0};
    height: 48px;
    border-radius: 5px;
    background-image: ${props =>
      `linear-gradient(to right, ${props.theme.colors.purple} 90%, ${props.theme.colors.white})`};
    transition: all 0.5s linear;
  }
`
const UploadBar = ({ max, progress }) => (
  <StyledUploadBar max={max} progress={progress}>
    <div className="upload-text">
      <h2>Feltöltés folyamatban...</h2>
    </div>
    <div className="bar-holder">
      <div className="bar"></div>
    </div>
  </StyledUploadBar>
)

export default UploadBar
